/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
    setTimeout(() => {
      if (document.getElementById("loader-wrapper")) {
        document.getElementById("loader-wrapper").style.display = "flex";
      }
    }, 0);
  };
  
  export const onInitialClientRender = () => {
    setTimeout(() => {
      if (document.getElementById("loader-wrapper")) {
        document.getElementById("loader-wrapper").style.display = "flex";
      }
    }, 0);
  };
  
  export const onRouteUpdate = () => {
    setTimeout(() => {
      if (document.getElementById("loader-wrapper")) {
        document.getElementById("loader-wrapper").style.visibility = "hidden" ;
        document.getElementById("loader-wrapper").style.opacity = "0";
        document.getElementById("loader-wrapper").style.transition = "visibility 0s 1s, opacity 1s linear";
      }
    }, 4000);
  };